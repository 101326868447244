import React from 'react'

export const HeroPattern = React.memo(({ className }) => (
  <svg
    width="1365"
    height="335"
    viewBox="0 0 1365 335"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    preserveAspectRatio="none"
  >
    <path
      opacity="0.22"
      d="M1365 14.3054H0V0H1365V14.3054ZM1365 20.7232H0V34.3426H1365V20.7232ZM1365 41.4463H0V54.3799H1365V41.4463ZM1365 62.2185H0V74.4662H1365V62.2185ZM1365 82.9417H0V94.5035H1365V82.9417ZM1365 103.665H0V114.541H1365V103.665ZM1365 124.388H0V134.578H1365V124.388ZM1365 145.111H0V154.615H1365V145.111ZM1365 165.834H0V174.604H1365V165.834ZM1365 186.606H0V194.69H1365V186.606ZM1365 207.33H0V214.727H1365V207.33ZM1365 228.053H0V234.765H1365V228.053ZM1365 248.776H0V254.802H1365V248.776ZM1365 269.499H0V274.839H1365V269.499ZM1365 290.271H0V294.925H1365V290.271ZM1365 310.994H0V314.963H1365V310.994ZM1365 331.718H0V335H1365V331.718Z"
      fill="#261548"
    />
  </svg>
))

HeroPattern.displayName = 'HeroPattern'
